import { Component, Input, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { WEBINAR_CONFERENCE_EVENTS } from 'src/app/conference/constants';
import { WebinarConferenceService } from 'src/app/conference/services';
import { INSIDE_EVENT } from 'src/app/constants';
import {
    DiagnosticService,
    EventEmitterService,
    RoomConnectionService,
    RtcService,
    UtilService,
    VideoWrapperService
} from 'src/app/core';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { MediaOrchestratorService } from '../../services/media-orchestrator.service';

@Component({
    selector: 'app-share-media-call-control',
    templateUrl: './share-media-call-control.component.html',
    styleUrls: ['./share-media-call-control.component.scss']
})
export class ShareMediaCallControlComponent implements OnInit {
    showMediaSharePopup: boolean = false;
    meetingObj: any;
    roomStatus: any;
    isVidyoMeeting: any;
    screenSharing: boolean = false;
    mediaShareSynced: boolean;
    subscriptions: any = [];
    showCloudVideoPreviewPopup: boolean = false;
    showStreamMixerPreviewPopup: boolean = false;
    videoService;
    isJioPc: boolean = false;
    @Input() mediaShared;
    @Input() shouldPlayRTMPInStream;
    @Input() showStream;
    @Input() mediaInfo;
    @Input() isHost;
    @Input() cloudPlayerConfig;
    gtmMeetingInfo: any;
    constructor(
        private videoWrapperService: VideoWrapperService,
        private roomConnectionService: RoomConnectionService,
        private diagnosticService: DiagnosticService,
        private rtcService: RtcService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private callStateManagerService: CallStateManagerService,
        private utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private webinarConferenceService: WebinarConferenceService,
        private zone: NgZone,
        public callViewStateManagerService: CallViewStateManagerService,
        public mediaOrchestratorService: MediaOrchestratorService,
        private googleTagManager: GoogleTagMangerService,
        private confirmationService: ConfirmationService
    ) {}

    ngOnInit(): void {
        this.videoService = this.videoWrapperService.getVideoService();
        this.meetingObj = this.callStateManagerService.getCurrentMeetingObject();
        this.roomStatus = this.callViewStateManagerService.roomStatus;
        this.isJioPc = this.utilService.isJioPcCheck();
        this.subscriptions.push(
            this.videoWrapperService
                .getVideoService()
                .getParticipantStatus()
                .subscribe((status) => {
                    this.zone.run(() => {
                        if (!status) {
                            return;
                        }
                        // this.roomLocked = status.roomLocked;
                        // this.isRecording = status.recording;
                        this.screenSharing = status.screenSharing;
                    });
                }),
            this.callViewStateManagerService.getViewStateObs().subscribe((event) => {
                if (event === 'cleanViewState' && this.showMediaSharePopup) {
                    this.toggle();
                }
            })
        );
        this.eventEmitterService.subscribe((event) => {
            switch (event.type) {
                case WEBINAR_CONFERENCE_EVENTS.CLOSE_SHAREPOPUP:
                    this.showMediaSharePopup = false;
                    break;
            }
        });
        this.gtmMeetingInfo = {
            meeting_Id: this.meetingObj?.meetingInfo._id,
            Category: this.meetingObj?.meetingInfo?.jioConf?.eventType,
            expected_attendee_count: this.meetingObj?.meetingInfo?.jioConf?.expectedAttendeeCount,
            tags: this.meetingObj?.meetingInfo?.jioConf?.tags
        };
    }

    ngOnChanges(change) {
        if (change.resetSharePopup && !this.showMediaSharePopup) {
            this.toggle();
        }
        if (change?.showStream) {
            this.showStream = change?.showStream?.currentValue;
        }
    }

    handleShareMediaControlClick() {
        if (this.callStateManagerService.checkParticipantType(null)) return;
        if (!this.callViewStateManagerService.isHost && this.mediaShared) {
            // Only a host can stop a cloud video.
            return;
        } else if (
            !this.screenSharing &&
            !this.mediaShared &&
            this.callViewStateManagerService.isHost &&
            !this.callStateManagerService.networkingRoom
        ) {
            this.toggle();
        } else if (
            this.callViewStateManagerService.roomStatus?.localParticipant?.isSharingScreen ||
            (this.mediaShared && this.callViewStateManagerService.isHost)
        ) {
            this.stopSharing();
        }
        // else if (
        //     !(this.callViewStateManagerService.isHost || this.callViewStateManagerService.roomStatus?.localParticipant?.isSharingScreen) ||
        //     (this.callStateManagerService.networkingRoom && !this.callViewStateManagerService.roomStatus?.localParticipant?.isSharingScreen)
        // ) {
        else {
            this.startShare();
            // this.toggle();
        }
        // } else if (this.screenSharing || this.mediaShared) {
        //     this.stopSharing();
        // } else {
        //     this.toggle();
        // }
    }

    toggle() {
        this.showMediaSharePopup = !this.showMediaSharePopup;
        this.eventEmitterService.emit({
            type: WEBINAR_CONFERENCE_EVENTS.TOGGLE_MEDIA_SHARE,
            data: this.showMediaSharePopup
        });
    }
    shareMediaCallControlOutside() {
        this.showMediaSharePopup = false;
    }

    startShare() {
        this.showMediaSharePopup = false;
        this.mediaOrchestratorService.startScreenShare();
    }

    shareCloudVideo() {
        this.showMediaSharePopup = false;
        // this.eventEmitterService.emit({
        //     type: WEBINAR_CONFERENCE_EVENTS.CLOUD_PLAYER_PREVIEW,
        //     data: null
        // });
        this.showCloudVideoPreviewPopup = true;
    }

    getSharingStatus() {
        return this.screenSharing || (this.mediaShared && this.mediaInfo.isHost);
    }

    stopSharing() {
        if (this.mediaShared) {
            this.mediaShareSynced = false;
            const localVidyoUser = this.videoWrapperService.getVideoService().getLocalParticipant();
            const payload = {
                action: 'stop',
                mediaId: this.mediaInfo.mediaId,
                jiomeetId: this.meetingObj.jiomeetId,
                participantUri: localVidyoUser.id
            };
            this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.SHARE_CLOUD_PLAYER_STOP, this.gtmMeetingInfo);
            this.webinarConferenceService.shareMedia(payload).subscribe(() => {
                this.diagnosticService
                    .sendEvent({
                        eventCategory: 'Jioconf Cloud video Inside Event',
                        eventAction: `Stop`,
                        eventType: 'app_event',
                        endpoint: 'media/share',
                        status: 'success'
                    })
                    .subscribe();
            });
        } else {
            this.googleTagManager.addMeetingBasicInfo(INSIDE_EVENT.SHARE_SCREEN_STOP, this.gtmMeetingInfo);
            this.roomConnectionService
                .toggleShareScreen(
                    this.meetingObj.jiomeetId,
                    this.callViewStateManagerService?.roomStatus?.localParticipant?.participantUri,
                    'stop'
                )
                .subscribe(() => {
                    this.callStateManagerService.getRoomConnectionStatus();
                    console.log('local screen stream', this.videoService.localScreenShareStream);
                    this.mediaOrchestratorService.stopScreenShare();
                });
        }
    }

    toggleStreamMixerSettings() {
        this.showMediaSharePopup = false;
        this.videoService.enableStreamMixer = true;
        this.showStreamMixerPreviewPopup = !this.showStreamMixerPreviewPopup;
    }
}
