import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AppService, EventEmitterService, UtilService } from 'src/app/core';
import { WebinarAttendeeService } from 'src/app/webinar-attendee';
import { WEBINAR_ATTENDEE_EVENTS } from 'src/app/webinar-attendee/constants';
import { FloatingReactionService } from '../../services/floating-reaction.service';
import * as _ from 'lodash';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { CallViewStateManagerService } from 'src/app/core/services/call-view-state-manager.service';
import { WebinarConferenceService } from 'src/app/conference/services';
import { AppLoggerService } from 'src/app/core/services/app-logger.service';
import { INSIDE_EVENT } from 'src/app/constants/gtm-events';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { WEBINAR_CONFERENCE_EVENTS } from 'src/app/conference/constants';

@Component({
    selector: 'app-reaction-controls',
    templateUrl: './reaction-controls.component.html',
    styleUrls: ['./reaction-controls.component.scss']
})
export class ReactionControlsComponent implements OnInit {
    subscriptions: any = [];
    floatingReactionFlag: any;
    webinarOptions: any;
    likeStatus = {
        state: false,
        type: 'like'
    };
    likeOptions: any = ['like', 'laugh'];
    likesCount: any;
    isCallOptionsPanelOpen: boolean;
    likesViewsTooltipText: string;
    sessionId: any;
    webinarId: any;
    webinarDetails: any;
    showReactionsBar: boolean = false;
    reactionBarStatus: any;
    reactions: any = [];
    reactionsControlBarCloseTimeout;

    constructor(
        private webinarService: WebinarAttendeeService,
        private appService: AppService,
        private toastrService: ToastrService,
        private floatingReaction: FloatingReactionService,
        private translateService: TranslateService,
        private eventEmitterService: EventEmitterService,
        private utilService: UtilService,
        public callStateManagerService: CallStateManagerService,
        public callViewStateManagerService: CallViewStateManagerService,
        private webinarConferenceService: WebinarConferenceService,
        private appLoggerService: AppLoggerService,
        private googleTagManager: GoogleTagMangerService
    ) {}

    ngOnInit(): void {
        this.webinarDetails = this.callStateManagerService.getParentWebinarDetails();
        console.log(this.webinarDetails?.webinar?.options?.likes?.reactionType);
        this.reactions = this.webinarDetails?.webinar?.options?.likes?.reactionType;
        //   this.reactions = this.meetingObj?.webinarOptions?.likes?.reactionType;
        this.subscriptions.push(
            this.webinarConferenceService.reaction$.subscribe((res) => {
                this.likeStatus.state = res.state;
                if (res.state) this.likeStatus.type = res.type;
            }),
            this.eventEmitterService.subscribe((event) => {
                switch (event.type) {
                    case WEBINAR_CONFERENCE_EVENTS.TOGGLE_MEDIA_SHARE: {
                        if (event.data) {
                            this.reactionbarOutside();
                        }
                        break;
                    }
                    case WEBINAR_CONFERENCE_EVENTS.CLOSE_SHAREPOPUP: {
                        this.reactionbarOutside();
                        break;
                    }
                }
            })
        );
    }

    setReactions() {
        // this.callViewStateManagerService.updateWebinarOptions();
        // this.webinarDetails = this.callStateManagerService.getParentWebinarDetails();
        // this.likeOptions = this.webinarDetails?.webinar?.options?.likes?.reactionType;
        this.reactions = this.callStateManagerService.meetingObj?.webinarOptions?.likes?.reactionType;
    }

    toggleReactionsBar() {
        if (!this.showReactionsBar) this.setReactions();
        this.showReactionsBar = !this.showReactionsBar;
    }

    handleReactionBarOnHover() {
        this.toggleReactionsBar();
    }

    extendReactionBarTimeout() {
        // clearTimeout(this.reactionsControlBarCloseTimeout);
        // this.showReactionsBar = true;
    }

    reactionBartoggle(value = null) {
        // clearTimeout(this.reactionsControlBarCloseTimeout);
        this.setReactions();
        this.reactionBarStatus = value ?? !this.reactionBarStatus;
        // this.usingCallControlsDropdown(this.reactionBarStatus);
    }

    handleLeaveHoverTrigger() {
        this.reactionsControlBarCloseTimeout = setTimeout(() => this.reactionBartoggle(false), 1000);
    }

    reactionbarOutside() {
        this.reactionBarStatus = false;
    }

    toggleLikeEvent(type) {
        if (!this.likeStatus.state) {
            let eventType = INSIDE_EVENT.REACTIONS;
            eventType.clicked_text = type;
            this.googleTagManager.pushGoogleTagData(eventType);
            this.webinarConferenceService
                .toggleLikeStatus(this.callStateManagerService.getCurrentMeetingId(), type)
                .subscribe(
                    () => {
                        if (this.callViewStateManagerService?.eventUpdatesObj?.likes?.floatingReactions?.enabled) {
                            this.floatingReaction.controlBarReactionAnimation(type, -500);
                        }
                    },
                    (err) => {
                        this.toastrService.error(
                            err?.error?.errors ||
                                this.translateService.instant('tostrs.something_went_wrong_try_again_later')
                        );
                        this.appLoggerService.error(
                            err?.error?.errors ||
                                this.translateService.instant('tostrs.something_went_wrong_try_again_later')
                        );
                    }
                );
        } else {
            this.webinarConferenceService
                .toggleUnlikeStatus(this.callStateManagerService.getCurrentMeetingId(), type)
                .subscribe(
                    () => {
                        this.likeStatus.type = '';
                        this.likeStatus.state = false;
                        setTimeout(() => {
                            if (document.getElementById('likeButton')) document.getElementById('likeButton').click();
                        }, 5);
                    },
                    (err) => {
                        this.toastrService.error(
                            err?.error?.errors ||
                                this.translateService.instant('tostrs.something_went_wrong_try_again_later')
                        );
                        this.appLoggerService.error(
                            err?.error?.errors ||
                                this.translateService.instant('tostrs.something_went_wrong_try_again_later')
                        );
                    }
                );
        }
    }

    updateReactedEmoji(type: string) {
        let eventType = INSIDE_EVENT.REACTIONS;
        eventType.clicked_text = type;
        this.googleTagManager.pushGoogleTagData(eventType);
        this.likeStatus.state = true;
        this.likeStatus.type = type;
        this.webinarConferenceService
            .toggleLikeStatus(this.callStateManagerService.getCurrentMeetingId(), type)
            .subscribe(
                () => {
                    if (this.callViewStateManagerService?.eventUpdatesObj?.likes?.floatingReactions?.enabled) {
                        this.floatingReaction.controlBarReactionAnimation(type, -500);
                    }
                    setTimeout(() => {
                        this.likeStatus.state = false;
                        this.likeStatus.type = '';
                    }, 1000); // Adjust timeout as needed
                },
                (err) => {
                    this.toastrService.error(
                        err?.error?.errors ||
                            this.translateService.instant('tostrs.something_went_wrong_try_again_later')
                    );
                    this.appLoggerService.error(
                        err?.error?.errors ||
                            this.translateService.instant('tostrs.something_went_wrong_try_again_later')
                    );
                }
            );
    }
}
