<div class="main-container" *ngIf="!isWebinarSettings">
    <div class="heading white-18-700-24">
        <span>{{ networkingRoom ? 'Edit Networking' : 'Create New' }} Room</span>
        <span class="closebtn">
            <img
                class="pointer"
                src="assets/img/webinar/side-nav/icon_Close.svg"
                draggable="false"
                (click)="closeFormSidePanel()"
                id="closeNtwRoomBtnIcon"
            />
        </span>
    </div>
    <div class="details-body">
        <form [formGroup]="networkingRoomDetailsForm">
            <div class="networking-room-category" *ngIf="enableCategoryOptionForNetworkingRoom">
                <label class="label-required">Room Category</label>
                <p-dropdown
                    [options]="categories"
                    placeholder="Select Category"
                    panelStyleClass="category-panel"
                    [(ngModel)]="category"
                    [styleClass]="'networking-room-category-dropdown'"
                    formControlName="category"
                >
                </p-dropdown>
            </div>

            <div class="networking-room-name-wrapper mt-2 mb-2">
                <label class="label-required" for="networking-room-name"
                    >Networking Room Name<span [style.color]="'#FF0000'">*</span></label
                >
                <input
                    id="networking-room-name"
                    class="networking-room-name je-form-input"
                    [class.focus-in]="networkingRoomDetailsForm?.value.name.length && showRoomNameCount"
                    type="text"
                    minlength="1"
                    maxlength="50"
                    formControlName="name"
                    (focus)="onFocus()"
                    (focusin)="showRoomNameCount = true"
                    (focusout)="showRoomNameCount = false"
                    placeholder="Enter name of Networking Room"
                />
                <img
                    src="assets/images/event-call-options/grey_solid_close_icon.svg"
                    alt=""
                    class="roomname-clear-icon pointer"
                    *ngIf="networkingRoomDetailsForm?.value.name.length && showRoomNameCount"
                    (mousedown)="clearFieldRoomname()"
                />
                <div
                    class="roomname-char-length"
                    [style]="{
                        visibility:
                            networkingRoomDetailsForm?.value.name.length && showRoomNameCount ? 'visible' : 'hidden'
                    }"
                    *ngIf="networkingRoomDetailsForm?.value.name.length && showRoomNameCount"
                >
                    <span style="font-size: 10px; font-family: JioMedium">
                        {{ networkingRoomDetailsForm?.value.name.length }}/50
                    </span>
                </div>
                <div class="error-text mt-2 mb-2" *ngIf="isError">
                    <span style="padding-right: 8px; display: inline-flex; height: 18px"
                        ><img src="assets/img/jioconf/misc/error-admin-support-functional-outline.svg" alt=""
                    /></span>
                    <span style="font-size: 12px">
                        {{ errorMessage }}
                    </span>
                </div>
            </div>
            <!-- <div>
                <label>Display Image</label>
                <div class="upload-section" appDragDrop (filesDropped)="handleDropFileEvent($event)" [class.error]="errors">
                    <div class="dnd-upper-section">
                        <img src="assets/img/webinar/side-nav/Upload-drag&Drop.svg" alt="Upload Image " />
                        <span class="dnd-text">
                            {{ 'webinar-content.drag_drop' | translate }}
                        </span>
                    </div>
                    <div class="separator">
                        {{ 'webinar-content.OR' | translate }}
                    </div>
                    <div class="dnd-bottom-section">
                        <input
                            #fileUpload
                            type="file"
                            (click)="fileUpload.value = null"
                            style="display: none"
                            accept="image/*"
                            (change)="fileChangeEvent($event.target.files[0])"
                        />
                        <button class="custom-button bg-secondary btn-upload" (click)="fileUpload.click()">
                            {{ 'webinar-content.upload' | translate }}
                        </button>
                    </div>
                </div>
                <label><span class="w-50">• File Type: GIF, JPEG, PNG </span>• File Size: Min 20KB Max 2MB</label>
            </div> -->
            <div class="form-group mt-4" style="margin-bottom: 0">
                <label class="m-0 mb-2">Display Image</label>
                <div class="img-upload-box" *ngIf="fileUploadSuccess || roomLogoUrl">
                    <div class="content d-flex justify-content-end mb-2" pTooltip="Delete" tooltipPosition="bottom">
                        <span class="pointer" (click)="discardFile()"
                            ><img
                                src="assets/img/jioconf/misc/trash_icon_green.svg"
                                alt="Delete"
                                width="24"
                                height="24"
                        /></span>
                    </div>
                    <div class="csv-upload-box">
                        <img [src]="roomLogoUrl || sanitize(blobURLref)" />
                        <!-- <img [src]="blobURLref" /> -->
                    </div>
                </div>
                <div
                    class="upload-section je-file-drop-container"
                    appDragDrop
                    (filesDropped)="handleDropFileEvent($event)"
                    [class.error]="errors?.image"
                    *ngIf="!fileUploadSuccess && !showProgressBar && !roomLogoUrl"
                >
                    <div class="dnd-upper-section">
                        <img src="assets/img/webinar/side-nav/Upload-drag&Drop.svg" alt="Upload Image " *ngIf="false" />
                        <span class="dnd-text">
                            {{ 'webinar-content.drag_drop' | translate }}
                        </span>
                    </div>
                    <div class="separator">
                        {{ 'webinar-content.OR' | translate }}
                    </div>
                    <div class="dnd-bottom-section">
                        <input
                            #nroomLogo
                            type="file"
                            (click)="nroomLogo.value = null"
                            style="display: none"
                            accept="image/*"
                            (change)="fileChangeEvent($event)"
                        />
                        <button
                            class="custom-button-revamp btn-primary lg w-auto btn-upload m-0"
                            (click)="nroomLogo.click()"
                            id="uploadImageBtnControl"
                        >
                            {{ 'webinar-content.upload' | translate }}
                        </button>
                    </div>
                </div>
                <div class="upload-section progress-section" *ngIf="!fileUploadSuccess && showProgressBar">
                    <span class="progress-indicator">{{ progress || 0 }}%</span>
                    <div class="progress-bar">
                        <div class="progress" [style.width.%]="progress || 0"></div>
                    </div>
                    <!-- <div class="cancel-wrapper">
                        <img
                            src="assets/img/webinar/close-circle-large.svg"
                            alt="Cancel Icon"
                            (click)="discardFile()"
                        />
                    </div> -->
                </div>
                <!-- <span class="text-error" *ngIf="errors?.image">* {{ errors?.image }}</span> -->
                <label class="mt-3 limit-label">
                    <span class="w-50 mr-3">• File Type: GIF, JPEG, PNG </span>
                    <span>• File Size: Min 20KB Max 2MB</span>
                </label>
            </div>
            <!-- remove below if removed from XD -->
            <div class="duration-type-section d-flex align-items-center my-3" *ngIf="false">
                <div class="session-time custom-form w-50">
                    <label class="label-required">Session Time (Minutes)</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Enter time in minutes"
                        id="duplicate-count"
                        autocomplete="off"
                        value="15"
                        min="1"
                        max="10"
                        maxlength="2"
                    />
                    <span class="session-time-limit">• Max 120 Min</span>
                </div>
                <div class="session-type d-flex justify-content-center align-items-center">
                    <span class="mr-2">Private Room </span>
                    <app-info-icon [tooltip]="'hello'"></app-info-icon>
                    <!-- <label class="switch">
                        <input type="checkbox" [checked]="false" (click)="toggleSessionType()" />
                        <span class="slider round"></span>
                    </label> -->
                    <div class="d-flex align-items-center ml-3">
                        <label class="toggle-switch">
                            <input type="checkbox" [checked]="isModerated" (click)="toggleRoomPrivacy()" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
            <ng-container
                [ngTemplateOutlet]="escapeRoomCheckbox"
                *ngIf="networkingRoomsService?.canEnableEscapeRooms"
            ></ng-container>
        </form>
    </div>
    <button
        class="custom-button-revamp btn-primary lg w-auto dark-theme networking-room-save"
        [class.disabled]="!networkingRoomDetailsForm.valid || networkingRoomDetailsForm.pristine || disableCreateRoom"
        (click)="submitNetworkingRoomForm()"
        id=" {{ networkingRoom ? 'Save' : 'Create' }}_btnControlToggle"
    >
        {{ networkingRoom ? 'Save' : 'Create' }}
    </button>
</div>

<div class="main-container webinar-settings-details-popup dark_theme" *ngIf="isWebinarSettings">
    <div class="heading">{{ networkingRoom ? 'Edit' : 'Create' }} Networking Room</div>
    <div>
        <form [formGroup]="networkingRoomDetailsForm">
            <div class="networking-room-category no-select" *ngIf="enableCategoryOptionForNetworkingRoom">
                <label class="label-required">Room Category</label>
                <p-dropdown
                    [options]="categories"
                    placeholder="Select Category"
                    panelStyleClass="category-panel"
                    [(ngModel)]="category"
                    [styleClass]="'networking-room-category-dropdown'"
                    formControlName="category"
                >
                </p-dropdown>
            </div>

            <div class="networking-room-name-wrapper mt-2 mb-2">
                <label class="label-required" for="networking-room-name"
                    >Networking Room Name<span [style.color]="'#FF0000'">*</span></label
                >
                <input
                    id="networking-room-name"
                    class="networking-room-name"
                    type="text"
                    minlength="1"
                    maxlength="50"
                    formControlName="name"
                    (focus)="onFocus()"
                    (focusin)="showRoomNameCount = true"
                    (focusout)="showRoomNameCount = false"
                    placeholder="Enter name of Networking Room"
                />
                <img
                    src="assets/images/attendee_emojis/new_icons/close_icon_green.svg"
                    alt=""
                    class="roomname-clear-icon"
                    *ngIf="networkingRoomDetailsForm?.value.name.length && showRoomNameCount"
                    (mousedown)="clearFieldRoomname()"
                />
                <div
                    class="roomname-char-length"
                    [style]="{
                        visibility:
                            networkingRoomDetailsForm?.value.name.length && showRoomNameCount ? 'visible' : 'hidden'
                    }"
                >
                    <span style="font-size: 10px; font-family: JioMedium">
                        {{ networkingRoomDetailsForm?.value.name.length }}/50
                    </span>
                </div>
                <div class="error-text mt-2 mb-2" *ngIf="isError">
                    <span style="padding-right: 8px; display: inline-flex; height: 18px"
                        ><img src="assets/img/jioconf/misc/error-admin-support-functional-outline.svg" alt=""
                    /></span>
                    <span style="font-size: 12px">
                        {{ errorMessage }}
                    </span>
                </div>
            </div>
            <!-- <div>
                <label>Display Image</label>
                <div class="upload-section" appDragDrop (filesDropped)="handleDropFileEvent($event)" [class.error]="errors">
                    <div class="dnd-upper-section">
                        <img src="assets/img/webinar/side-nav/Upload-drag&Drop.svg" alt="Upload Image " />
                        <span class="dnd-text">
                            {{ 'webinar-content.drag_drop' | translate }}
                        </span>
                    </div>
                    <div class="separator">
                        {{ 'webinar-content.OR' | translate }}
                    </div>
                    <div class="dnd-bottom-section">
                        <input
                            #fileUpload
                            type="file"
                            (click)="fileUpload.value = null"
                            style="display: none"
                            accept="image/*"
                            (change)="fileChangeEvent($event.target.files[0])"
                        />
                        <button class="custom-button bg-secondary btn-upload" (click)="fileUpload.click()">
                            {{ 'webinar-content.upload' | translate }}
                        </button>
                    </div>
                </div>
                <label><span class="w-50">• File Type: GIF, JPEG, PNG </span>• File Size: Min 20KB Max 2MB</label>
            </div> -->
            <div class="form-group no-select" style="margin-bottom: 0">
                <div class="on-upload">
                    <label class="m-0 mb-2">Display Image</label>
                    <div
                        class="content d-flex justify-content-end mb-2"
                        pTooltip="Delete"
                        tooltipPosition="bottom"
                        *ngIf="fileUploadSuccess || roomLogoUrl"
                    >
                        <span class="pointer" (click)="discardFile()"
                            ><img
                                src="assets/img/jioconf/misc/trash_icon_green.svg"
                                alt="Delete"
                                width="24"
                                height="24"
                        /></span>
                    </div>
                </div>
                <div class="csv-upload-box" *ngIf="fileUploadSuccess || roomLogoUrl">
                    <img [src]="roomLogoUrl || sanitize(blobURLref)" />
                    <!-- <img [src]="blobURLref" /> -->
                </div>
                <div
                    class="upload-section"
                    appDragDrop
                    (filesDropped)="handleDropFileEvent($event)"
                    [class.error]="errors?.image"
                    *ngIf="!fileUploadSuccess && !showProgressBar && !roomLogoUrl"
                >
                    <div class="dnd-upper-section">
                        <img src="assets/img/webinar/side-nav/Upload-drag&Drop.svg" alt="Upload Image " />
                        <span class="dnd-text">
                            {{ 'webinar-content.drag_drop' | translate }}
                        </span>
                    </div>
                    <div class="separator">
                        {{ 'webinar-content.OR' | translate }}
                    </div>
                    <div class="dnd-bottom-section">
                        <input
                            #nroomLogo
                            type="file"
                            (click)="nroomLogo.value = null"
                            style="display: none"
                            accept="image/*"
                            (change)="fileChangeEvent($event)"
                        />
                        <button class="custom-button bg-secondary btn-upload" (click)="nroomLogo.click()">
                            {{ 'webinar-content.upload' | translate }}
                        </button>
                    </div>
                </div>
                <div class="upload-section progress-section" *ngIf="!fileUploadSuccess && showProgressBar">
                    <span class="progress-indicator">{{ progress || 0 }}%</span>
                    <div class="progress-bar">
                        <div class="progress" [style.width.%]="progress || 0"></div>
                    </div>
                    <!-- <div class="cancel-wrapper">
                        <img
                            src="assets/img/webinar/close-circle-large.svg"
                            alt="Cancel Icon"
                            (click)="discardFile()"
                        />
                    </div> -->
                </div>
                <label class="mt-2"
                    ><span class="w-50 mr-3">• File Type: GIF, JPEG, PNG </span>• File Size: Min 20KB Max 2MB</label
                >
            </div>
            <ng-container
                [ngTemplateOutlet]="escapeRoomCheckbox"
                *ngIf="networkingRoomsService?.canEnableEscapeRooms"
            ></ng-container>
            <!-- remove below if removed from XD -->
            <div class="duration-type-section d-flex align-items-center my-3" *ngIf="false">
                <div class="session-time custom-form w-50">
                    <label class="label-required">Session Time (Minutes)</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Enter time in minutes"
                        id="duplicate-count"
                        autocomplete="off"
                        value="15"
                        min="1"
                        max="10"
                        maxlength="2"
                    />
                    <span class="session-time-limit">• Max 120 Min</span>
                </div>
                <div class="session-type d-flex justify-content-center align-items-center">
                    <span class="mr-2">Private Room </span>
                    <app-info-icon [tooltip]="'hello'"></app-info-icon>
                    <!-- <label class="switch">
                        <input type="checkbox" [checked]="false" (click)="toggleSessionType()" />
                        <span class="slider round"></span>
                    </label> -->
                    <div class="d-flex align-items-center ml-3">
                        <label class="toggle-switch">
                            <input type="checkbox" [checked]="isModerated" (click)="toggleRoomPrivacy()" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button
        class="custom-button bg-primary w-100 dark-theme networking-room-save"
        [class.disabled]="!networkingRoomDetailsForm.valid || networkingRoomDetailsForm.pristine || disableCreateRoom"
        (click)="submitNetworkingRoomForm()"
    >
        {{ networkingRoom ? 'Save Changes' : 'Create Room' }}
    </button>
</div>

<ng-template #escapeRoomCheckbox>
    <div class="escape-room-switch m-0 my-4 no-select" [class.disabled]="networkingRoom">
        <p-checkbox
            class="mr-2 p-checkbox-legacy"
            [(ngModel)]="isEscapeRoom"
            [binary]="true"
            (onChange)="escapeRoomSettingToggled()"
            id="escape-room-toggle-checkbox"
        ></p-checkbox>
        <label class="m-0">This is an Escape Room</label>
    </div>
</ng-template>
