<div class="join-call-component-wrapper d-flex justify-content-center align-items-center h-100">
    <div
        class="page container-fluid call-preview-wrapper attendee-networking-join-main w-100 px-5 py-4"
        [class.event-bg]="true"
        *ngIf="!joinBeforeHost && !waitingRoomDetails?.waitingRoom && !allowAccessUI && !loading"
        [class.guest-speaker-container]="!isAuthenticated"
        [class.hide]="autoJoin"
    >
        <div class="header-section" *ngIf="!isprerecordedSession">
            <div *ngIf="isAuthenticated" class="title" [class.white-text]="true">
                {{
                    isNetworkingRoomCall
                        ? 'You’re about to join a Networking Room'
                        : isUserHost
                        ? 'You’re about to join ' + (isSession ? 'a Session...' : 'an Event...')
                        : 'You’re about to enter the backstage'
                }}
            </div>
            <div *ngIf="!isAuthenticated" class="title" [class.white-text]="true">
                You're about to join
                {{
                    !isNetworkingRoomCall
                        ? 'an event...'
                        : 'a ' + (networkingRoom?.networking?.isEscapeRoom ? 'Escape' : 'Networking') + ' Room'
                }}
            </div>
            <div class="side-note">
                {{ 'joinmeeting_page_or_joincall.pleasemsg' | translate }}
            </div>
        </div>
        <div
            class="d-flex justify-content-center preview-container-wrapper"
            [class.guest-speaker]="!isAuthenticated"
            [class.networking-room]="isNetworkingRoomCall"
        >
            <div class="preview-container">
                <div class="call-preview">
                    <app-call-preview
                        (showsettings)="openSettingsPopup($event)"
                        [hostMeeting]="false"
                        [networkingRoom]="isNetworkingRoomCall"
                        [isprerecordedSession]="isprerecordedSession"
                    ></app-call-preview>
                    <!-- <div class="header-section" *ngIf="!isAuthenticated">
                        <div class="side-note mt-1">
                            {{ 'joinmeeting_page_or_joincall.pleasemsg' | translate }}
                        </div>
                    </div> -->
                </div>

                <div
                    class="col p-0 w-100 text-center"
                    *ngIf="isAuthenticated && errors.global"
                    [class.disabled]="showPermissionUI"
                >
                    <!-- <button
                    type="button"
                    (click)="join()"
                    class="custom-button bg-primary join-btn"
                    [class.disabled]="joining || showPermissionUI"
                >
                    {{
                        (joining ? 'joinmeeting_page_or_joincall.please_wait' : 'joinmeeting_page_or_joincall.join')
                            | translate
                    }}
                </button> -->
                </div>
                <div
                    class="display-join my-2 mx-auto w-100"
                    *ngIf="isAuthenticated && errors.global != 'This event has ended' && !isprerecordedSession"
                >
                    <div class="display-name-label mb-1">Display Name</div>
                    <div class="d-flex align-items-center justify-content-center">
                        <input
                            [(ngModel)]="displayName"
                            class="display-name mr-2"
                            maxlength="101"
                            (keydown)="spaceValidation($event)"
                            [disabled]="isprerecordedSession"
                        />
                        <button
                            class="custom-button-revamp btn-primary sm quad-pad w-auto m-0"
                            (click)="join()"
                            [class.disabled]="disableStartbtn"
                        >
                            {{ isNetworkingRoomCall ? 'Enter' : isUserHost ? 'Start' : 'Join' }}
                        </button>
                    </div>
                </div>
                <div class="media-settings mx-auto w-100" *ngIf="!isprerecordedSession">
                    <div class="setting camera-settings">
                        <!-- <div> -->
                        <img src="assets/images/joining-preview/camera_white_on.svg" height="16" width="16" />
                        <!-- </div> -->
                        <app-video-settings
                            class="w-100 ml-2"
                            [route]="'call-preview'"
                            [networkingRoom]="isNetworkingRoomCall"
                            [settingPopup]="'joinCall'"
                        ></app-video-settings>
                    </div>
                    <div class="setting mic-settings">
                        <img src="assets/images/joining-preview/mic_white_on.svg" height="16" width="16" />
                        <app-audio-settings
                            class="w-100 ml-2"
                            [route]="'call-preview'"
                            [networkingRoom]="isNetworkingRoomCall"
                            [settingPopup]="'joinCall'"
                        ></app-audio-settings>
                    </div>
                </div>
                <div class="pre-call" [class.networking-precall]="isNetworkingRoomCall" *ngIf="isJMMeeting">
                    <img
                        class="testImg"
                        src="assets/img/precalltest/pre-call-test.svg"
                        alt="pre call testing"
                        (click)="openSettingsPopup(null, true)"
                    />
                    <div class="side-note">
                        {{ 'joinmeeting_page_or_joincall.precalltest' | translate }}
                    </div>
                </div>
            </div>
            <div class="right-content" *ngIf="!isAuthenticated">
                <div class="component-wrap">
                    <div class="form-wrap w-100">
                        <section *ngIf="!loggedInOnly" class="w-100">
                            <!-- <h3 class="title white-text mb-4">
                                {{ 'joinmeeting_page_or_joincall.title' | translate }}
                            </h3> -->
                            <form [formGroup]="form" (submit)="join()" class="w-100">
                                <div class="je-form-input-label-wrapper">
                                    <label for="fname" class="m-0 mb-2 je-form-label"
                                        >{{ 'joinmeeting_page_or_joincall.fname' | translate
                                        }}<span class="text-error">*</span></label
                                    >
                                    <input
                                        type="text"
                                        class="je-form-input"
                                        id="fname"
                                        (keydown.enter)="$event.preventDefault()"
                                        formControlName="fname"
                                        placeholder="{{ 'joinmeeting_page_or_joincall.fnameplaceholder' | translate }}"
                                        [appInputMaxLength]="30"
                                        customClass="je-form-limit-count"
                                    />
                                </div>
                                <div class="je-form-input-label-wrapper">
                                    <label for="lname" class="m-0 mb-2 je-form-label"
                                        >{{ 'joinmeeting_page_or_joincall.lname' | translate
                                        }}<span class="text-error">*</span></label
                                    >
                                    <input
                                        type="text"
                                        class="je-form-input"
                                        id="lname"
                                        (keydown.enter)="$event.preventDefault()"
                                        formControlName="lname"
                                        placeholder="{{ 'joinmeeting_page_or_joincall.lnameplaceholder' | translate }}"
                                        [appInputMaxLength]="30"
                                        customClass="je-form-limit-count"
                                    />
                                </div>
                                <div class="je-form-input-label-wrapper">
                                    <label for="userIdentity" class="m-0 mb-2 je-form-label">{{
                                        'joinmeeting_page_or_joincall.userIdentity' | translate
                                    }}</label>
                                    <input
                                        class="je-form-input"
                                        id="userIdentity"
                                        (keydown.enter)="$event.preventDefault()"
                                        formControlName="userIdentity"
                                        placeholder="{{
                                            'joinmeeting_page_or_joincall.userIdentityplaceholder' | translate
                                        }}"
                                        [appInputMaxLength]="50"
                                        customClass="je-form-limit-count"
                                    />
                                </div>
                                <span class="is-invalid" *ngIf="form?.controls.userIdentity.errors?.pattern">
                                    {{ 'joinmeeting_page_or_joincall.errors.invalid_userIdentity' | translate }}
                                </span>
                                <span class="text-error" *ngIf="errors.global">{{ errors.global }}</span>
                                <div class="col p-0 w-100 mt-4">
                                    <button
                                        type="submit"
                                        class="custom-button-revamp btn-primary m-0"
                                        [class.disabled]="!form.valid || joining || showPermissionUI"
                                    >
                                        {{
                                            (joining
                                                ? 'joinmeeting_page_or_joincall.please_wait'
                                                : isHost
                                                ? 'joinmeeting_page_or_joincall.start'
                                                : isNetworkingRoomCall
                                                ? 'joinmeeting_page_or_joincall.enter'
                                                : 'joinmeeting_page_or_joincall.join'
                                            ) | translate
                                        }}
                                    </button>
                                </div>
                            </form>
                        </section>
                        <section *ngIf="loggedInOnly" class="signin-container">
                            <h2 class="text-center mb-2 text-color">Please login.</h2>
                            <div class="d-flex justify-content-center sub-title mt-3">
                                Host has restricted only logged-in users to join this meeting
                            </div>
                            <div class="col p-0 w-100 mt-5 d-flex justify-content-center">
                                <button class="btn primary w-50 button-color" (click)="navigateToLogin()">
                                    <a class="text-white">Sign In</a>
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="allowAccessUI && !recorderToken" class="page container-fluid" [class.guest-speaker-container]="true">
        <div class="d-flex justify-content-center access-info-container" [class.guest-speaker]="!isAuthenticated">
            <div class="access-info-preview">
                <img
                    *ngIf="isNonSafariBrowserFromMacOrIos"
                    class="stepsImage"
                    src="assets/img/access_info_mac_chrome.png"
                    alt="media access info"
                />
                <img
                    *ngIf="isNonSafariBrowserFromWindowsOrAndroid"
                    class="stepsImage"
                    src="assets/img/access_info_win_chrome.png"
                    alt="media access info"
                />
                <img
                    *ngIf="isSafari"
                    class="stepsImage"
                    src="assets/img/access_info_safari.svg"
                    alt="media access info"
                />
            </div>
            <div class="access-info-text">
                <div class="access-info-heading">Allow access to camera and mic</div>
                <div class="access-info-subheading">
                    To enable your audio and video, click <b>Allow</b> in the browser pop-up above. You may still turn
                    them off later at any time
                </div>
            </div>
        </div>
    </div>

    <section *ngIf="joinBeforeHost" class="err-scenario join-call-waiting">
        <div class="waiting-room-text-wrapper">
            <app-loader [position]="'relative'"></app-loader>
            <h4>{{ 'joinmeeting_page_or_joincall.waiting_for_host_to_start' | translate }}</h4>
        </div>
    </section>

    <section *ngIf="waitingRoomDetails?.waitingRoom" class="err-scenario join-call-waiting">
        <div class="waiting-room-text-wrapper">
            <app-loader [position]="'relative'"></app-loader>
            <h4>{{ 'joinmeeting_page_or_joincall.please_wait_while' | translate }}</h4>
        </div>
    </section>

    <footer *ngIf="waitingRoomDetails?.waitingRoom" class="waiting-room-footer fixed-bottom text-center">
        <button type="button" class="custom-button" (click)="leaveWaitingRoom()">
            <img src="assets/images/speaker-call-wrapper/speaker_leave_icon.svg" alt="" />
        </button>
    </footer>

    <ng-template viewHost></ng-template>
    <!-- <app-permission-denied *ngIf="showPermissionUI && !allowAccessUI" [showSkipOption]="true"></app-permission-denied> -->
    <app-loader *ngIf="(loading || autoJoin) && !(joinBeforeHost || waitingRoomDetails?.waitingRoom)"></app-loader>
    <div [ngStyle]="{ display: preCallTestingService.preCallTestError ? 'block' : 'none' }">
        <app-devicemodal-error (closeModalEvent)="onCloseModalEvent()" (restartClicked)="openSettingsPopup(null, true)">
        </app-devicemodal-error>
    </div>
</div>
