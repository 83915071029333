import { Component, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BillingService, EventData, EventEmitterService, UserService, UtilService } from 'src/app/core';
import { DiagnosticService } from 'src/app/core';
import { APP_CONSTANT, APP_EVENTS, DASHBOARD_HEADER } from 'src/app/constants';
import { ToastrService } from 'ngx-toastr';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { EventEmitter } from '@angular/core';
import { ValidationService } from 'src/app/core';
import { ConfirmationService } from 'primeng/api';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Component({
    selector: 'app-profile-details',
    templateUrl: './profile-details.component.html',
    styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit, OnChanges {
    options = [
        { type: 'host', title: 'I’m here to host an event' },
        { type: 'attend', title: 'I’m here to attend events' },
        { type: 'both', title: 'I’m here to host and attend an event' }
    ];

    selectedPurpose;
    previousPurpose;
    currentUser;
    profilePicPath;
    purpose: string;
    isTextUnderlined: boolean = false;
    form: FormGroup;
    socialMediaForm: FormArray;
    showform: boolean = true;
    showprofile: boolean = true;
    title = 'My Profile';
    errors: any = {};
    selectedTab: string = 'profile';
    checkDisabled;
    initialInterests;
    isMobile: boolean;
    profileChange = false;
    subscriptions = [];
    logo$;
    imageURL: string;
    imageChangedEvent: {};
    imageTypeToBeCropped: string;
    isHovered: boolean = false;
    croppedImage: Blob;
    showCropper: boolean = false;
    uploadForm: FormGroup;
    isDefaultPic: boolean;
    transform: ImageTransform = { scale: 1 };
    scale: number = 1;
    editButton = false;
    isMobileDevice;
    isLandscapeMode;
    isIpad;
    showDiscardChangePopup = false;
    showDeleteProfilePicPopup = false;
    closeProfileFrom = null;
    changesInProgress = false;
    socialMediaAdded = [];
    @Input() isDashboard: boolean = false;
    isIphone: boolean;
    expiresOn;
    eventsPlanName;
    eventsProStatus: boolean;
    showInvoices: boolean = false;
    invoices: any;
    constructor(
        private userService: UserService,
        private diagnosticService: DiagnosticService,
        private toastrService: ToastrService,
        private validationService: ValidationService,
        private fb: FormBuilder,
        private googleTagMangerService: GoogleTagMangerService,
        private confirmationService: ConfirmationService,
        public utilService: UtilService,
        private eventEmitterService: EventEmitterService,
        private translateService: TranslateService,
        private router: Router,
        private billingService: BillingService
    ) {}
    interests = [];
    interestList = [
        'art',
        'music',
        'design',
        'education',
        'tech',
        'sports',
        'food',
        'health',
        'money',
        'travel',
        'gaming',
        'lifestyle',
        'causes',
        'blogging',
        'volunteering',
        'marketing',
        'podcast',
        'photography'
    ];

    @Output() showprofiledetails = new EventEmitter();

    ngOnInit(): void {
        this.isIphone = this.utilService.isMobileIOSBrowser();
        this.isMobile = this.utilService.isResponsiveMobileView();
        this.isMobileDevice = this.utilService.isMobileBrowser() || this.isMobile;
        this.isIpad = this.utilService.isIpad();
        this.onResize(null);
        this.currentUser = this.userService.getUserSync();
        this.purpose = this.currentUser?.jioConf?.purpose[0] || '';
        this.getUserDetails();
        this.uploadForm = this.fb.group({
            profile: ['']
        });

        this.userService.getUser().subscribe((user) => {
            this.profilePicPath = this.userService.getProfilePicPath(user._id);
            if (user.imageMeta?.path) {
                this.editButton = true;
            }
        });
        this.utilService.getSubscriptionsId().subscribe((res) => {
            this.invoices = res;
        });
        // this.setDefaultValue();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        this.isLandscapeMode = window.innerWidth > window.innerHeight;
    }

    ngOnChanges() {
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.UPDATE_PROFILE || event.type === APP_EVENTS.NAME_CHANGED) {
                this.currentUser = this.userService.getUserSync();
                this.profilePicPath = this.userService.getProfilePicPath(this.currentUser._id);
                this.currentUser = this.userService.getUserSync();
                this.isDefaultPic = !this.currentUser?.imageMeta && !this.currentUser?.imageMeta?.path;
            }
        });
    }

    selectPurpose(purpose) {
        this.selectedPurpose = purpose.type;
    }

    listenToFormChanges() {
        this.form.valueChanges.subscribe((res) => {
            const charRegex = new RegExp('^[a-zA-Z0-9_,.@ -]*$');
            this.errors.department = !charRegex.exec(res.department)
                ? 'Only -, @ and _ special characters are allowed'
                : false;
            this.errors.jobTitle = !charRegex.exec(res.jobTitle)
                ? 'Only -, @ and _ special characters are allowed'
                : false;
            if (this.form.value?.department?.length >= APP_CONSTANT.PROFILE_LIMIT) {
                this.toastrService.error(
                    this.translateService.instant('tostrs.edit_profile_character_limit_exceeded', {
                        value: APP_CONSTANT.PROFILE_LIMIT
                    })
                );
                return;
            }
            if (this.form.value?.jobTitle?.length >= APP_CONSTANT.PROFILE_LIMIT) {
                this.toastrService.error(
                    this.translateService.instant('tostrs.edit_profile_character_limit_exceeded', {
                        value: APP_CONSTANT.PROFILE_LIMIT
                    })
                );
                return;
            }
        });
    }

    hidedetails() {
        this.showprofiledetails.emit('close');
    }

    toggleForm(para1: string) {
        this.showform = !this.showform;
        if (this.showform) {
            this.getUserDetails();
            this.errors = {};
        }
        this.interests = [...this.initialInterests];
        this.checkDisabled = false;
        if (para1 == 'Discard') {
            let EventData = DASHBOARD_HEADER.EDIT_PROFILE;
            EventData.outcome = 'discard';
            this.googleTagMangerService.pushGoogleTagData(EventData);
        }
    }

    change() {
        this.showform = !this.showform;
        this.title = this.showform ? 'My Profile' : 'Edit Profile';
        // this.selectedSocialMedia = this.availableSocialMediaOptions[0];
    }

    changeprofile(id) {
        this.selectedTab = id;
        if (id == 'profile') {
            this.showprofile = true;
        } else {
            this.showprofile = false;
        }
    }
    getUserDetails() {
        this.currentUser = this.userService.getUserSync();
        if (this.currentUser?.socialMediaLinks) {
            this.socialMediaAdded = Object.keys(this.currentUser?.socialMediaLinks);
        }
        this.form = this.fb.group({
            name: [this.currentUser?.name || '', Validators.required],
            lname: [this.currentUser?.lname || '', Validators.required],
            email: [this.currentUser?.email || ''],
            phoneNo: [this.currentUser?.phoneNo || ''],
            department: [this.currentUser?.department || '', this.validationService.noWhitespaceValidator],
            jobTitle: [this.currentUser?.jobTitle || '', this.validationService.noWhitespaceValidator],
            socialMediaLinks: this.fb.array([])
        });
        this.socialMediaForm = this.form.get('socialMediaLinks') as FormArray;
        this.selectedPurpose = this.currentUser?.jioConf?.purpose[0] || '';
        this.previousPurpose = this.selectedPurpose;
        this.interests = this.currentUser.jioConf?.interestAreas || [];
        this.initialInterests = [...this.interests];
        this.eventsProStatus = this.currentUser?.eventsProStatus && this.currentUser?.eventsPlanName?.length > 0;
        this.expiresOn = this.currentUser?.eventsSubscriptionInfo?.expiresOn;
        this.eventsPlanName = this.currentUser?.eventsPlanName;
        this.listenToFormChanges();
    }
    handleSocialMediaFormValue(formData: any) {
        this.socialMediaForm = formData;
    }

    handlechangesInProgress(value: boolean) {
        this.changesInProgress = value;
    }

    openSocialMediaLink(social) {
        let link = this.currentUser?.socialMediaLinks[social];
        if (link.indexOf('http://') !== 0 && link.indexOf('https://') !== 0) {
            link = 'https://' + link;
        }
        window.open(link, '_blank');
    }

    downloadInvoice() {
        this.billingService.downloadInvoice().subscribe(
            (res: any) => {
                window.location.href = res?.data?.invoiceUrl;
                this.toastrService.success(this.translateService.instant('tostrs.downloading_in_progress'));
            },
            (err) => {
                console.error('Error while downloading Invoice', err);
                this.toastrService.error(this.translateService.instant('tostrs.something_went_wrong_please_try_again'));
            }
        );
    }

    toggleInvoices() {
        this.showInvoices = !this.showInvoices;
    }

    downloadInvoiceById(id) {
        this.billingService.downloadInvoiceById(id).subscribe(
            (res: any) => {
                console.log(res);
                window.location.href = res?.data?.invoiceUrl;
                this.toastrService.success(this.translateService.instant('tostrs.downloading_in_progress'));
            },
            (err) => {
                console.error('Error while downloading Invoice', err);
                this.toastrService.error(this.translateService.instant('tostrs.something_went_wrong_please_try_again'));
            }
        );
    }

    downloadAllInvoices() {
        this.invoices.forEach((invoice) => {
            this.downloadInvoiceById(invoice._id);
        });
    }

    submitForm() {
        let formData = this.form.value;
        const socialMediaLinks = {};
        Object.entries<any>(this.socialMediaForm.value).forEach(([index, entry]) => {
            const platform = entry.dropdown.toLowerCase();
            const url = entry.text;

            if (url && typeof url === 'string') {
                socialMediaLinks[platform] = url;
            }
        });
        const payload = {
            name: formData.name,
            lname: formData.lname,
            email: formData.email,
            phoneNo: formData.phoneNo,
            department: formData.department,
            socialMediaLinks,
            jobTitle: formData.jobTitle,
            jioConf: {
                purpose: this.selectedPurpose ? [this.selectedPurpose] : [],
                interestAreas: this.interests
            }
        };
        delete payload?.email;
        delete payload?.phoneNo;
        if (!this.currentUser?.isOTT) {
            delete payload?.name;
            delete payload?.lname;
        }
        if (!this.form.value?.lname) {
            delete payload?.lname;
        }
        this.userService.updateProfile(payload).subscribe(
            () => {
                this.changesInProgress = false;
                this.diagnosticService
                    .sendEvent({
                        eventCategory: 'Jioconf Profile',
                        eventAction: `${this.selectedPurpose}`,
                        eventType: 'app_event',
                        endpoint: '/api/my_profile/update',
                        status: 'success'
                    })
                    .subscribe();
                this.change();
                this.getUserDetails();
                // this.setDefaultValue();
            },
            (err) => {
                this.toastrService.error(
                    err?.error?.errors || err?.error?.errorsArray?.[0]?.message || 'Something went wrong'
                );
            }
        );
        let EventData = DASHBOARD_HEADER.EDIT_PROFILE;
        EventData.outcome = 'save';
        this.googleTagMangerService.pushGoogleTagData(EventData);
        this.checkDisabled = false;
        this.changeprofile('profile');
    }

    selectInterest(interest) {
        if (!this.interests.includes(interest)) {
            this.interests.push(interest);
        } else {
            this.interests.splice(this.interests.indexOf(interest), 1);
        }
        this.checkDisabled = true;
    }

    closeProfile(param: string) {
        const hasChanges =
            this.form?.dirty ||
            this.selectedPurpose !== this.previousPurpose ||
            this.checkDisabled ||
            this.changesInProgress;

        this.closeProfileFrom = param;
        if (hasChanges) {
            this.showDiscardChangePopup = true;
        } else {
            this.handleAcceptDiscard(param);
        }
    }

    handleAcceptDiscard(param) {
        switch (param) {
            case 'cancel':
                this.toggleForm('Discard');
                break;
            case 'close':
                this.hidedetails();
                break;
        }
    }

    fileChangeEvent(file) {
        if (file && !(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
            this.toastrService.error(
                this.translateService.instant('tostrs.please_select_a_jpg_jpeg_or_png_image_file')
            );
            return;
        }
        this.imageChangedEvent = file;
        this.showCropper = true;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.blob;
        this.uploadForm.get('profile').setValue(this.croppedImage);
    }

    deleteProfilePic() {
        this.userService.deleteProfilePicture().subscribe(
            () => {
                this.toastrService.success('Deleted Succesfully');
                this.userService.getUser().subscribe((userDetails) => {
                    this.profilePicPath = this.userService.getProfilePicPath(userDetails._id);
                    this.editButton = false;
                });
            },
            () => {
                this.toastrService.error('Error,Please try again later');
            }
        );
    }

    deletePicture() {
        this.showDeleteProfilePicPopup = true;
    }

    croppedFileEmitter(event) {
        const formData = new FormData();
        formData.append('file', this.uploadForm.get('profile').value);
        this.userService.uploadProfilePicture(formData).subscribe(
            (res: any) => {
                if (res.success) {
                    this.diagnosticService
                        .sendEvent({
                            eventAction: 'Choose Photo',
                            eventCategory: 'Web Profile',
                            eventType: 'app_event',
                            endpoint: '/api/my_profile/uploadPic',
                            status: 'success'
                        })
                        .subscribe();
                    this.toastrService.success('Profile picture updated succesfully');
                    this.userService.getUser().subscribe((userDetails) => {
                        this.profilePicPath = this.userService.getProfilePicPath(userDetails._id);
                        if (userDetails.imageMeta?.path) {
                            this.editButton = true;
                        }
                    });
                } else {
                    this.toastrService.error(this.translateService.instant('tostrs.failed_to_update_profile_picture'));
                }
            },
            (err) => {
                this.diagnosticService
                    .sendEvent({
                        eventAction: 'Choose Photo',
                        eventCategory: 'Web Profile',
                        eventType: 'api_failure',
                        endpoint: '/api/my_profile/uploadPic',
                        status: 'failed',
                        data: err.error
                    })
                    .subscribe();
                console.log(err);
            }
        );
        this.toggleCropper();
    }

    toggleCropper() {
        this.showCropper = false;
    }

    imageLoaded() {
        this.scale = 1;
    }

    zoom() {
        this.transform = {
            scale: this.scale
        };
    }

    zoomIn() {
        if (this.scale > 4) {
            this.scale = 5;
        } else {
            this.scale += 1;
        }
        this.transform = {
            scale: this.scale
        };
    }

    zoomOut() {
        if (this.scale < 2) {
            this.scale = 1;
        } else if (this.scale !== 1) {
            this.scale = this.scale - 1;
        }
        this.transform = {
            scale: this.scale
        };
    }

    navigateToDashboard() {
        this.router.navigateByUrl('/contactus');
        this.closeProfile('close');
    }
}
