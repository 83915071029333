<div class="login-v1-app">
    <div class="page container-fluid" *ngIf="loading || samlLoginInProgress">
        <app-loader></app-loader>
    </div>
    <div class="page container-fluid" *ngIf="!mailSent">
        <div class="close" (click)="close()" *ngIf="isLoginFromLandingPage">
            <img src="assets/img/close_icn_white.svg" alt="" />
        </div>
        <div class="row h-100">
            <div class="right-content p-4">
                <div class="component-wrap pb-3">
                    <div class="form-wrap" *ngIf="!loading && !samlLoginInProgress">
                        <section>
                            <a class="d-flex justify-content-around" routerLink="/home">
                                <div class="d-flex align-items-center" style="margin-bottom: 20px">
                                    <img
                                        src="assets/img/jioevents/logos/jioevent_logo_white.svg"
                                        class="jio-text-img"
                                        alt=""
                                    />
                                </div>
                            </a>

                            <div class="note mt-3" style="margin-bottom: 48px">
                                Sign In or Create a new account with your contact details
                            </div>
                            <form
                                *ngIf="!emailLogin && !ottLogin"
                                [formGroup]="usernameForm"
                                class="custom-form login-details"
                            >
                                <div class="form-group">
                                    <label *ngIf="!!usernameForm.value.username">Email ID or Mobile Number</label>
                                    <input
                                        #emailOrMobileNumberInput
                                        type="text"
                                        class="form-control login-input"
                                        id="username"
                                        formControlName="username"
                                        aria-describedby="usernameHelp"
                                        [class.is-invalid]="errors.username || userNotFound"
                                        placeholder="Enter Email ID or Mobile Number"
                                        (keyup.enter)="submitUsername()"
                                    />
                                    <span class="d-inline-block mt-2 invalid-feedback" *ngIf="errors.username">{{
                                        errors.username
                                    }}</span>
                                    <span class="d-inline-block mt-2 text-error isinvalid" *ngIf="errors.global">{{
                                        errors.global
                                    }}</span>
                                </div>
                                <button
                                    type="button"
                                    id="proceedButton"
                                    class="custom-button bg-login my-3"
                                    [class.disabled]="!enableProceedBtn"
                                    (click)="submitUsername()"
                                >
                                    {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.proceed') | translate }}
                                </button>
                            </form>

                            <form
                                class="custom-form"
                                *ngIf="emailLogin"
                                [formGroup]="loginForm"
                                (submit)="submitEmailPassword()"
                            >
                                <div class="form-group">
                                    <label for="email" [class.invalid]="errors.global">{{
                                        'signin_page.emailaddress' | translate
                                    }}</label>
                                    <input
                                        type="text"
                                        class="form-control login-input"
                                        id="email"
                                        formControlName="email"
                                        aria-describedby="emailHelp"
                                        [class.is-invalid]="errors.email || userNotFound"
                                        [class.invalid]="errors.global"
                                        (input)="onEmailChanged($event)"
                                        placeholder="{{ 'signin_page.enteremail' | translate }}"
                                    />
                                    <span class="invalid-feedback mr-2">{{ errors.email }}</span>
                                </div>
                                <div class="form-group" *ngIf="!isDomainEmail">
                                    <label for="password" [class.invalid]="errors.global">{{
                                        'signin_page.password' | translate
                                    }}</label>
                                    <div class="row">
                                        <div class="col-md-12" id="passwordfield">
                                            <input
                                                [type]="showPassword ? 'text' : 'password'"
                                                class="form-control login-input"
                                                id="password"
                                                #password
                                                formControlName="password"
                                                [class.is-invalid]="errors.password"
                                                [class.invalid]="errors.global"
                                                placeholder="{{ 'signin_page.password' | translate }}"
                                            />
                                            <div class="eye position-relative">
                                                <img
                                                    src="{{
                                                        showPassword
                                                            ? 'assets/img/password_view_icon.svg'
                                                            : 'assets/img/hide_password_icon.svg'
                                                    }}"
                                                    (click)="togglePassword()"
                                                    class="eye"
                                                />
                                            </div>
                                            <span
                                                class="d-flex align-items-center mt-2 invalid-feedback mr-2"
                                                *ngIf="errors.password"
                                                ><img
                                                    class="mr-2"
                                                    src="assets/img/wrong_red_icon.svg"
                                                    width="15"
                                                    height="15"
                                                />
                                                {{ errors.password }}</span
                                            >
                                            <span
                                                class="d-flex align-items-center mt-2 text-error mr-2"
                                                *ngIf="errors.global"
                                            >
                                                <img
                                                    class="mr-2"
                                                    src="assets/img/wrong_red_icon.svg"
                                                    width="15"
                                                    height="15"
                                                />
                                                {{ errors.global }}
                                            </span>
                                            <div
                                                class="d-block riptide-btn pointer mt-3"
                                                (click)="navToForgotPassword()"
                                            >
                                                {{ 'signin_page.forgot' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    id="signinButton"
                                    class="custom-button bg-login my-4"
                                    [class.disabled]="
                                        (!isDomainEmail && !loginForm.valid) ||
                                        (isDomainEmail && loginForm.controls.email.errors) ||
                                        loggingIn
                                    "
                                >
                                    {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.signin') | translate }}
                                </button>
                            </form>

                            <form
                                *ngIf="ottLogin && !requestingOTP"
                                [formGroup]="ottForm"
                                (submit)="verifyOTP()"
                                class="custom-form"
                            >
                                <div class="form-group position-relative">
                                    <label>{{ 'signin_page.mobileno' | translate }}</label>
                                    <input
                                        type="text"
                                        class="form-control login-input"
                                        id="mobileno"
                                        formControlName="phoneNo"
                                        aria-describedby="phoneNoHelp"
                                        [class.is-invalid]="errors.phoneNo || userNotFound"
                                        placeholder="{{ 'signin_page.mobileno' | translate }}"
                                        disabled
                                    />
                                    <span class="invalid-feedback">{{ errors.phoneNo }}</span>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-12" id="passwordfield">
                                            <label>{{ 'signin_page.enter_six_digit_otp' | translate }}</label>
                                            <ng-otp-input
                                                #ngOtpInput
                                                class="otp-input-field"
                                                [class.invalid]="errors?.global || errors?.otp"
                                                (onInputChange)="handeOtpChange($event)"
                                                (fill)="handleFillEvent($event)"
                                                [config]="config"
                                            >
                                            </ng-otp-input>
                                            <div class="d-flex" style="margin-top: 10px">
                                                <div class="d-flex align-items-center">
                                                    <img
                                                        class="mr-2"
                                                        src="assets/img/wrong_red_icon.svg"
                                                        width="15"
                                                        height="15"
                                                        *ngIf="errors?.otp || errors?.global"
                                                    />
                                                </div>
                                                <span
                                                    class="d-inline-block invalid-feedback mr-2"
                                                    *ngIf="errors.otp"
                                                    style="margin-top: 0 !important"
                                                >
                                                    {{ errors.otp }}
                                                </span>
                                                <span
                                                    class="d-inline-block mt-2 text-error mr-2"
                                                    *ngIf="errors.global"
                                                    >{{ errors.global }}</span
                                                >
                                            </div>
                                            <div class="d-block riptide-btn pointer mt-3" (click)="getOTP()">
                                                {{ 'signin_page.resend_otp' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    id="verifyButton"
                                    class="custom-button bg-login my-4"
                                    [class.disabled]="disableOttLoginForm()"
                                >
                                    <!-- {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.signin') |translate }} -->
                                    {{ 'signin_page.proceed' | translate }}
                                </button>
                            </form>

                            <form
                                *ngIf="ottLogin && requestingOTP"
                                [formGroup]="ottForm"
                                (submit)="getOTP()"
                                class="custom-form"
                            >
                                <div class="form-group position-relative">
                                    <input
                                        type="text"
                                        id="phoneNo"
                                        class="form-control login-input"
                                        id="email"
                                        formControlName="phoneNo"
                                        aria-describedby="phoneNoHelp"
                                        [class.is-invalid]="errors.phoneNo"
                                        placeholder="Mobile No"
                                    />
                                    <span class="d-inline-block mt-2 invalid-feedback" *ngIf="errors.phoneNo">{{
                                        errors.phoneNo
                                    }}</span>
                                    <span class="d-inline-block mt-2 text-error" *ngIf="errors.global">{{
                                        errors.global
                                    }}</span>
                                </div>
                                <button
                                    type="submit"
                                    id="getOtpButton"
                                    class="custom-button bg-login my-4"
                                    [class.disabled]="!ottForm.valid"
                                >
                                    <!-- {{ (loggingIn ? 'signin_page.pleasewait' : 'signin_page.getotp') |translate }} -->
                                    {{ 'signin_page.proceed' | translate }}
                                </button>
                            </form>
                            <div class="note note-terms" *ngIf="!ottLogin || requestingOTP">
                                {{ 'signin_page.by_signing_up' | translate
                                }}<b class="pointer" (click)="goToPp()">{{
                                    'landing_or_home_page.footer.privacy_policy' | translate
                                }}</b>
                                and
                                <b class="pointer" (click)="goToTnc()">{{
                                    'signin_page.terms_of_service' | translate
                                }}</b
                                >.
                            </div>
                        </section>
                        <section class="social-login" *ngIf="(!ottLogin || requestingOTP) && !isJioPc">
                            <div class="separator"></div>
                            <div class="social-login-btns">
                                <div class="social-login-btn pointer" (click)="loginWithSSO('google')">
                                    <div class="icon-wrap google"></div>
                                    <!-- <div class="text">{{'signin_page.google' | translate}}</div> -->
                                </div>
                                <!-- <div class="social-login-btn pointer" (click)="loginWithSSO('facebook')">
                                    <div class="icon-wrap facebook"></div>
                                </div> -->
                                <!-- <div class="social-login-btn pointer" (click)="loginWithSSO('linkedin')">
                  <div class="icon-wrap linkedin"></div>
                </div> -->
                                <!-- <div class="social-login-btn pointer" routerLink="/sso">
                  <div class="icon-wrap">
                    <img src="assets/img/sso_login_icon.svg" />
                  </div>
                </div> -->
                            </div>
                            <!-- <div class="disclaimer text-center">
                By signing up, I agree to the
                <a class="pointer blue" (click)="launchPrivacy()">privacy policy</a>
                and
                <a class="pointer blue" (click)="launchTnc()">terms of service</a>
              </div> -->
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="page container-fluid scrollable" *ngIf="mailSent && !loading">
        <div class="signup-component post-email">
            <div class="header email-verify-header" *ngIf="!unVerifiedAccount">
                {{ 'signin_page.waiting_email_verification' | translate }}
            </div>
            <div class="header" *ngIf="unVerifiedAccount">{{ 'email_verification.waiting' | translate }}</div>
            <div class="img-wrap" style="padding-top: 32px">
                <img style="width: 184px" src="assets/img/verify_mail_sent.svg" />
            </div>
            <div class="d-flex flex-column top-text py-4">
                <!-- <span *ngIf="unVerifiedAccount">{{'email_verification.looks_like' | translate}}</span>
          <span>{{'email_verification.sent' | translate}} {{ signupForm.get('emailId').value }},</span> -->
                <span class="yet-to-verify">{{ 'signin_page.yet_to_verify' | translate }}</span>
                <div class="d-flex justify-content-center align-items-center email-n-edit">
                    <span class="email-text">{{ usernameForm.get('username').value }}</span>
                    <span class="ml-4 pointer edit-action-btn" (click)="editEmail()">
                        <img src="assets/img/edit_riptid_icon.svg" />
                        <span class="ml-2 riptide-btn font-weight-normal">{{ 'signin_page.edit' | translate }}</span>
                    </span>
                </div>
                <!-- <div>{{'email_verification.click' | translate}}</div> -->
            </div>
            <div class="bottom-text">
                <div class="mail-bottom-text">
                    {{ 'email_verification.havent' | translate }}
                    <a class="pointer riptide-btn d-inline ml-2 no-select" (click)="resendEmail()">{{
                        'email_verification.resend' | translate
                    }}</a>
                </div>
            </div>
            <div class="sign-in">
                <a class="pointer riptide-btn" (click)="navigateToLogin()">{{
                    'email_verification.signin' | translate
                }}</a>
            </div>
        </div>
    </div>
</div>
